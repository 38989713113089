import React, { useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  LocationSelect,
  MobileScreen,
} from 'components'
import { headerBg, logo, logoTitle, viewMenu } from 'images'
import { dispatch } from 'store'

const orderingUrl =
  process.env.REACT_APP_ENVIRONMENT === 'dev'
    ? 'https://demo.codefusion.tech/restaurants/ebisu/locations/ebisuLocation1'
    : 'https://order.codefusion.tech/restaurants/8GFwb8UTNOQ2rbtqc9kk/locations/Y7GXtSFgqD6z5v9Jpd8q'

const images = []

export default () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <MobileScreen>
        <CFView bg="#4C5D29" column center pv="8px">
          <CFImage w="80%" src={logo} alt="Oliv Chicken Logo" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          column
          center
          w="100%"
          zIndex={98}
          maxWidth="1400px"
          bg="#4C5D29"
          pv="5px"
        >
          <CFView row justifyBetween alignCenter w="100%" ml="140px">
            <CFImage
              pt="5px"
              pb="3px"
              w="335px"
              src={logo}
              alt="Oliv Chicken Logo"
              zIndex={98}
            />
          </CFView>
        </CFView>
      </DefaultScreen>
      <ModalGateway>
        {showModal ? (
          <Modal onClose={() => setShowModal(false)}>
            <Carousel views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}
